.label {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.5;
  padding: 0.3rem 0.2rem;
  display: inline-block;
  margin-bottom: 0;
}

.input {
  height: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  outline: 0;
  margin-bottom: 0.8rem;
  border: 1px solid;
  background-color: var(--content-background);
  border-color: var(--border-colour);
  color: inherit;
}

.entityRelation {
  background-color: var(--input-background) !important;
  border-color: var(--border-colour) !important;
  color: inherit !important;
}

.inputCodeField {
  background: transparent !important;
  border: transparent !important;
}

.codeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputCode {
  margin: auto;
  text-align: center;
  height: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: auto;
}

.inputCharacter {
  border: 1px solid;
  background-color: #e8e8e8;
  border-color: var(--border-colour);
  border-radius: 50%;
  line-height: 2.4rem;
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
  padding: 0.8rem;
}

.range {
  padding: 0 !important;
}

/* Select Field */
.selectContainer {
  position: relative;
}

.selectButton {
  margin-bottom: 0.8rem;
  position: relative;
  box-sizing: border-box;
  border: 1px solid;
  cursor: pointer;
  padding: 0.2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.unselectedOption {
  color: grey;
}

.selectOptions {
  /* display: none; */
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  /* position: absolute; */
  /* width: 100%; */
  background-color: white;
  z-index: 100;
}

.visibleSelectOptions {
  display: block;
  box-sizing: border-box;
}

.selectOption {
  cursor: pointer;
  /* padding: 0.2rem; */
}

.selectOption:hover {
  background-color: cadetblue;
}

.selectedOption:after {
  content: "\2713";
  margin-left: 8px;
}

.selectedOption {
  font-weight: 900;
}

.currencyInput::placeholder {
  color: transparent;
}

.currencyInput:focus::placeholder {
  color: inherit;
}

.dateContainers, .dateContainers > div {
  display: flex;
  justify-content: stretch;
}

.dateContainers input {
  flex-grow: 1;
}

.dateContainers:has(input:not([value=""])) ~ label,
.dateContainers:focus-within ~ label {
  /* margin-top: -1.2rem; */
  margin-top: var(--top-spacing);
  pointer-events: all;
  white-space: initial;
}

.selectOptionsInner {
  background-color: transparent !important;
}

svg:has( + .selectInput), .selectInput {
  color: var(--primary-text-colour);
}

.activeInput {
  color: initial;
}